
// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBoYS725m4H22hWMjrTc2w4tgilBFWTGi8",
    authDomain: "prophone-7ac29.firebaseapp.com",
    projectId: "prophone-7ac29",
    storageBucket: "prophone-7ac29.appspot.com",
    messagingSenderId: "976923472924",
    appId: "1:976923472924:web:4950eb5fb0f5e295761bd9",
    measurementId: "G-MVK20F2SMV"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

// Google Auth Provider
const googleProvider = new GoogleAuthProvider();

// Export auth and googleProvider, without `signInWithPopup` here
export { auth, googleProvider };
