import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {  FaEdit} from 'react-icons/fa';

import Sidebar from '../components/navbar';

import deletes from '../icons/delete.svg';

import google from '../icons/google.png';
import { Modal,Button } from 'react-bootstrap'
import API_BASE_URL from '../components/config';

import { useLocation } from 'react-router-dom';

function Notifications() {
  const [id, setUserid] = useState('');
  const [totalusers, setTotalUser] = useState('0');
  const [plan, setSelectedPlan] = useState('');
  const [users, setUsers] = useState([]);

 
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);


  const [updateshowModal, setupdateShowModal] = useState(false);
  const handleupdateModalOpen = () => setupdateShowModal(true); 
  const handleupdateModalClose = () => setupdateShowModal(false);

  
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  
  const addNotifcation = async (event,id) => {
    event.preventDefault();
    // setCancelsucc(false)

   
   
    try {
        const response= await axios.post(`${API_BASE_URL}/api/create-notification`, {
            title : title,
            message : message,
          });
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };

  

  const sendnotification = async (event,userid) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Send?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/send-notification/${userid}`);
      
      alert("Notification has been Sent")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const deleteaccount = async (event,userid) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Delete?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/delete-notification/${userid}`);
      
      alert("Notification has been Deleted")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    // Set background color
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
  
    const fetchContacts = async () => {
      const id = sessionStorage.getItem("login");
      // alert(id)
      if (id == 1) {
        setAuthenticated(true);
        // setUserid(id);

        try {
          const response = await axios.post(`${API_BASE_URL}/api/fetch-notification`);
          // console.log(response)
  
          setUsers(response.data)
          
          
        } catch (error) {
          alert('error')
          // Handle error if necessary
        
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchContacts();
  }, []);
  
  const handleLogin = async (userid) => {
   
    sessionStorage.setItem("changed",true)
    sessionStorage.setItem("login", userid);
    // const confirmed = window.confirm('Are you sure you want to Suspend This Account?');
    // if (!confirmed) {
    //   return; 
    // }
    window.location.href = "/"
    try {
     
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const handleSuspend = async (id) => {
    setShowModal(true)
    setUserid(id);
  };
  const updateplan = async (id) => {
    handleupdateModalOpen()
    setUserid(id);
  };

  
  

  const changeUserplan = async (event) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Change the Plan?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-user-plan/${id}`);
      
      alert("Plan Has Been Updated")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
         <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addNotifcation(event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>Create Notification</h4>
        </div>
        <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Title:</label>
                <input type="text" placeholder="Message Title" required onChange={(e) => setTitle(e.target.value)}  className="form-control" id="recipient-name"  />
              </div>
              <div className="form-group">
                <br></br>
                <label htmlFor="recipient-name" className="col-form-label mb-2">Message Body:</label>

              <textarea  value={message} onChange={(e) => setMessage(e.target.value)} className="p-3"  required style={{width: '100%',
    height: '100px'}}></textarea>
              </div>
       
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <div style={{ display: 'flex' }}>
              <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary save_web_btn">Submit</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={updateshowModal} contentClassName="custom-modal-content" onHide={handleupdateModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => changeUserplan(event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>UPDATE PLAN</h4>
        </div>
        <div className="modal-body ">
        <div className="d-flex">
          <p>PLAN : </p>
          
        <select value={plan} onChange={(e) => setSelectedPlan(e.target.value)} className="form-select custom-dorpdown-toglle mt-1" id="mySelect" >
                <option value="business-pro-legacy"> Business Pro (Legacy)</option>
                <option value="free"> Free</option>
                
            </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <div style={{ display: 'flex' }}>
              <button type="button" className="btn btn-secondary cancel_btn" onClick={handleupdateModalClose} data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary save_web_btn">Submit</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      
       
       
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
      
       

            

<div className=" mb-2" style={{textAlign:'left'}}>
          <h4 className="float-left text-white">Push Notifications </h4>
          <Link  type="button" onClick={() => handleSuspend("45")}  className="btn btn_primary save_btn float-right"   >
        <img  alt="" className="mr-3" />&nbsp;&nbsp;Create Notification
        </Link>
        </div>
<div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
        <tr>
                  
                  <th>Title</th>
                  <th>Message</th>
                  
                  <th>Send </th>
                  <th>Action </th>
                  
                  
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
                                          
      {users.map((item) => (
          <tr key={item.id}>
            <td style={{textWrap: 'pretty'}}>{item.title}</td>
            <td style={{textWrap: 'pretty'}}>{item.message}</td>
            
            
          
    
                
            <td>
              
  <Link onClick={(event) => sendnotification(event,item._id)}  type="button" className="btn btn-success save_btn">
    Send
  </Link>
</td>
<td>
                <button type="button" className="btn  btn-danger  " onClick={(event) => deleteaccount(event,item._id)}  
        
        >Delete</button>
                </td>

        </tr>
        
        ))}
      
                      
                      
                  
                  
                                        </tbody>
    </table>
    
  </div>


       
       
        </div>
       
          
        </Sidebar>
        );
      }
    
}

export default Notifications;
