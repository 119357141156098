import React, { useEffect, useState,useRef } from "react";
import { Link, useNavigate  } from 'react-router-dom';
import { TelnyxRTC } from '@telnyx/webrtc';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaUserCircle, FaCog, FaSignOutAlt,FaBell,FaInbox,FaCalendar,FaPhone, FaMicrophone,FaMicrophoneSlash } from 'react-icons/fa';
import axios from 'axios';
import { Modal,Button } from 'react-bootstrap'
import API_BASE_URL from '../components/config';
import alerticon from '../icons/alert-icon.png';
import phone from '../icons/phone.png';
import banner from '../icons/banner.gif';
import Ringtone from '../assets/Ringtone.mp3';
import Hangup from '../assets/HangUp.mp3';

// import '../css/core-dark.css';
// import '../css/theme-default.css';
function Header() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); 
  const [showupgradeModal, setupgradeShowModal] = useState(false); 
  const handleupgradeModalOpen = () => setupgradeShowModal(true);
  
  const [acceptcall, setAccepted] = useState(false); 
  const [callstatus, setStatus] = useState('');

    const handleModalOpen = () => setShowModal(true);
    const pages = [
      'audience',
      'campaign',
      'create_audience',
      'view_audience',
      'sms_cam_templates',
      'create_template',
      'create_campaign',
    ]
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [sipusername, setSipusername] = useState('');
  const [email, setEmail] = useState('');
  const [profileimage, setProfileimage] = useState('');
  const [balanced, setBalance] = useState('');
  const [unread, setUnread] = useState(0);
  const [showlogoutModal, setShowlogoutModal] = useState(false);
  const [isuerloggedin, setUserLoggedin] = useState(false);
  const audioRef = useRef(null);
  const remoteAudioRef = useRef(null);
const HangupRef = useRef(null);
const [client, setClient] = useState(null);
const [call, setCall] = useState(null);
const [caller, setCaller] = useState('');
  const handlelogoutModalOpen = () => setShowlogoutModal(true); 
  const handlelogoutModalClose = () =>{
    logout()
    setShowlogoutModal(false);
}
const handleupgradeModalclose = () =>{
  navigate('/')
     setupgradeShowModal(true);

}
const [incommingcallmodal, setincommingcallmodal] = useState(false);
  
  
const handleincommingcallModalOpen = () => {
  // let intervalId;
  
  // if (audioRef.current) {
  //   // Set up an interval to play the dial tone repeatedly
  //   intervalId = setInterval(() => {
  //     audioRef.current.play().catch(error => console.error());
  //   }, 3000); // Play every 3 seconds, adjust timing as needed
  // }
  setincommingcallmodal(true); 
}
useEffect(() => {
  let intervalId;

  if (!acceptcall && callstatus.length > 1 && callstatus === "ringing") {
    if (audioRef.current) {
      // Set up an interval to play the dial tone repeatedly
      intervalId = setInterval(() => {
        audioRef.current.play().catch(error => console.error());
      }, 2000); // Play every 3 seconds, adjust timing as needed
    }
  }

  if (callstatus === "destroy") {
    setAccepted(false)
    setStatus('');
    setCall(null);
    
    clearInterval(intervalId);
    setCallDuration(0)
  }

  // Cleanup on unmount or when callstatus changes
  return () => {
    clearInterval(intervalId);
  };
}, [callstatus]);
const [callDuration, setCallDuration] = useState(0);
  const [callInterval, setCallInterval] = useState(null);
const handleincommingcallModalClose = () =>{
if (!call){

  clearInterval(callInterval);
  setCallDuration(0)
  setincommingcallmodal(false);
  }
}



  const handleModalClose = () => {
    setShowModal(false);
    navigate('/integerations'); // Navigate to home page on modal close
  };
  const [authenticated, setAuthenticated] = useState(null);
  const [chkimagename, setCheckimagename] = useState(null);
  
   const formatPhoneNumber = (number) => {
    const normalizedNumber = number.replace(/\D/g, ''); 

    if (normalizedNumber.length === 0) return ''; // Handle empty input
    if (normalizedNumber.length <= 3) return `(${normalizedNumber})`; // Ensure both brackets
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;

    // Handle numbers longer than 10 digits
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
};


//   useEffect(() => {
//     const client = new TelnyxRTC({
//         login: 'prophone',  // Replace with your SIP username
//         password: 'prophone',  // Replace with your SIP password
//         realm: 'sip.telnyx.com'
//     });

//     client.on('telnyx.ready', () => {
//         console.log('Telnyx WebRTC client ready');
//     });

//     client.on('telnyx.error', (error) => {
//         console.error('Telnyx WebRTC error:', error);
//     });

//     client.on('telnyx.notification', (notification) => {
//       console.log(notification)
//       const callState = notification.call.state;
//       setStatus(callState)
//       // if (callState === 'trying' || callState === 'calling' || callState === 'active') {
//       //   setStatus(callState.charAt(0).toUpperCase() + callState.slice(1));
//       // }
//         if (notification.type === 'callUpdate' && notification.call.state === 'ringing') {
//         setCallDuration(0)
            
//             handleincommingcallModalOpen()
          
//           audioRef.current.play()
            
//             setCall(notification.call);
//             setCaller(notification.call.options.remoteCallerNumber);
//             setStatus('ringing')
//         }

//         if (notification.type === 'callUpdate' && notification.call.state === 'hangup') {
//           HangupRef.current.play()
//         setCallDuration(0)
          
//           handleincommingcallModalClose()
//           setAccepted(false)

//             setCall(null);
//             setCaller('');
//             setCallInterval(null)
//             clearInterval(callInterval);
//         setCallDuration(0)
        
//         }
//         if (notification.type === 'callUpdate' && notification.call.state === 'active') {
//           const remoteStream = notification.call.remoteStream;
//           if (remoteStream && remoteAudioRef.current) {
//             remoteAudioRef.current.srcObject = remoteStream;
//             remoteAudioRef.current.play().catch(error => {
//               console.error();
//             });
//           }
//           if (callInterval) {
//             clearInterval(callInterval);
//           }
//           setCallDuration(0);
//           const interval = setInterval(() => {
//             setCallDuration((prevDuration) => prevDuration + 1);
//           }, 1000);
//           setCallInterval(interval);
//         }
//     });

//     client.connect();
//     setClient(client);

//     return () => {
//         // client.disconnect();
//       clearInterval(callInterval);

//     };
// }, []);
const handleAccept = () => {
  audioRef.current.pause()
  setAccepted(true)
        if (call) {
            call.answer();
        }
    };

    const handleDecline = () => {
      audioRef.current.pause()
      setCallDuration(0)

      setCallInterval(null)
            clearInterval(callInterval);
        setCallDuration(0)
      HangupRef.current.play()
        if (call) {
            call.hangup();
        }
    };
    const logoutadmin = () => {
    sessionStorage.setItem("login", 1);
    sessionStorage.removeItem("changed")
      window.location.reload()
    };

     
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    
      const intervalId = setInterval(async () => {
        try {
          const id = sessionStorage.getItem("login");
          const checkchange = sessionStorage.getItem("changed");
          if(checkchange){
            setUserLoggedin(true)
          }else{
            setUserLoggedin(false)

          }
          if (id) {
            
            try {
              // const {data: { firstname, lastname,email,profile,balance,apikey }} = await axios.post(`${API_BASE_URL}/api/userinfo`, {
              //   id,
              //   dd:sessionStorage.getItem("dd")
              // });
              const response = await axios.post(`${API_BASE_URL}/api/userinfo`, {
                id,
                dd: sessionStorage.getItem("dd"),
                changed: sessionStorage.getItem("changed"),
              });
              // alert(response.data.id)
               if (id != 1 && response.data.id == 5){
                // alert("found")
                handlelogoutModalOpen()
                return;
              }
              const { firstname, lastname, email, profile, balance, apikey,unread,sip,plan } = response.data;
              setFirstname(firstname)
              setLastname(lastname)
              setEmail(email)
              setUnread(unread)
              setBalance(balance)
              setSipusername(sip)
              var currentPage = window.location.pathname.split("/").pop(); // Assumes the page name is the last part of the URL
              // alert(response.data.id)
              if (pages.includes(currentPage)) {
                // Check if the API key is missing or empty
                if (!apikey || apikey.length < 1) {
                    handleModalOpen();
                } else if (!plan || plan === 'business-starter') {
                    // If the API key exists, but the plan is missing or is 'business-starter', open the upgrade modal
                    handleupgradeModalOpen();
                }
            }
        
              const imageUrl = 'https://app.prophone.io/static/media/'  + profile;
              setProfileimage(imageUrl)
              setCheckimagename(profile)
              
            } catch (error) {
              
            }
          } else {
            navigate("/login");
          }
        } catch (error) {
          alert('error')
        }
      }, 6000);
      
      return () => clearInterval(intervalId);
    
  }, []); 

  useEffect(() => {
    if (!sipusername) {
      return; // Don't run the effect if sipusername is not set
    }
  
    const client = new TelnyxRTC({
      login: sipusername, // Replace with your SIP username
      password: 'prophone1sip', // Replace with your SIP password
      realm: 'sip.telnyx.com',
    });
  
    const handleTelnyxReady = () => {
      // console.log('Telnyx WebRTC client ready');
    };
  
    const handleTelnyxError = (error) => {
      // console.error('Telnyx WebRTC error:', error);
    };
  
    const handleTelnyxNotification = (notification) => {
      // console.log(notification);
      const callState = notification.call.state;
      setStatus(callState);
  
      if (notification.type === 'callUpdate' && notification.call.state === 'ringing') {
        console.log("call is comming");
        setCallDuration(0);
        handleincommingcallModalOpen();
        audioRef.current.play();
        setCall(notification.call);
        setCaller(notification.call.options.remoteCallerNumber);
        setStatus('ringing');
      }
  
      if (notification.type === 'callUpdate' && notification.call.state === 'hangup') {
        HangupRef.current.play();
        setCallDuration(0);
        handleincommingcallModalClose();
        setAccepted(false);
        setCall(null);
        setCaller('');
        setCallInterval(null);
        clearInterval(callInterval);
        setCallDuration(0);
      }
  
      if (notification.type === 'callUpdate' && notification.call.state === 'active') {
        const remoteStream = notification.call.remoteStream;
        if (remoteStream && remoteAudioRef.current) {
          remoteAudioRef.current.srcObject = remoteStream;
          remoteAudioRef.current.play().catch((error) => {
            console.error(error);
          });
        }
        if (callInterval) {
          clearInterval(callInterval);
        }
        setCallDuration(0);
        const interval = setInterval(() => {
          setCallDuration((prevDuration) => prevDuration + 1);
        }, 1000);
        setCallInterval(interval);
      }
    };
  
    client.on('telnyx.ready', handleTelnyxReady);
    client.on('telnyx.error', handleTelnyxError);
    client.on('telnyx.notification', handleTelnyxNotification);
  
    client.connect();
    setClient(client);
  
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // Optionally, pause any active calls here
      } else if (document.visibilityState === 'visible') {
        if (!client.isConnected()) {
          client.connect();
        }
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      client.disconnect();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(callInterval);
    };
  }, [sipusername]);
  
// useEffect(() => {
//   if (!sipusername) {
//       return; // Don't run the effect if sipusername is not set
//     }
//   const client = new TelnyxRTC({
//     login: sipusername, // Replace with your SIP username
//     password: 'prophone1sip', // Replace with your SIP password
//     realm: 'sip.telnyx.com',
//   });

//   const handleTelnyxReady = () => {
//     // console.log('Telnyx WebRTC client ready');
//   };

//   const handleTelnyxError = (error) => {
//     // console.error('Telnyx WebRTC error:', error);
//   };

//   const handleTelnyxNotification = (notification) => {
//     // console.log(notification);
//     const callState = notification.call.state;
//     setStatus(callState);

//     if (notification.type === 'callUpdate' && notification.call.state === 'ringing') {
//       setCallDuration(0);
//       handleincommingcallModalOpen();
//       audioRef.current.play();
//       setCall(notification.call);
//       setCaller(notification.call.options.remoteCallerNumber);
//       setStatus('ringing');
//     }

//     if (notification.type === 'callUpdate' && notification.call.state === 'hangup') {
//       HangupRef.current.play();
//       setCallDuration(0);
//       handleincommingcallModalClose();
//       setAccepted(false);
//       setCall(null);
//       setCaller('');
//       setCallInterval(null);
//       clearInterval(callInterval);
//       setCallDuration(0);
//     }

//     if (notification.type === 'callUpdate' && notification.call.state === 'active') {
//       const remoteStream = notification.call.remoteStream;
//       if (remoteStream && remoteAudioRef.current) {
//         remoteAudioRef.current.srcObject = remoteStream;
//         remoteAudioRef.current.play().catch((error) => {
//           console.error();
//         });
//       }
//       if (callInterval) {
//         clearInterval(callInterval);
//       }
//       setCallDuration(0);
//       const interval = setInterval(() => {
//         setCallDuration((prevDuration) => prevDuration + 1);
//       }, 1000);
//       setCallInterval(interval);
//     }
//   };

//   client.on('telnyx.ready', handleTelnyxReady);
//   client.on('telnyx.error', handleTelnyxError);
//   client.on('telnyx.notification', handleTelnyxNotification);

//   client.connect();
//   setClient(client);

//   const handleVisibilityChange = () => {
//     if (document.visibilityState === 'hidden') {
//       client.disconnect();
//     } else if (document.visibilityState === 'visible') {
//       client.connect();
//     }
//   };

//   document.addEventListener('visibilitychange', handleVisibilityChange);

//   return () => {
//     client.disconnect();
//     document.removeEventListener('visibilitychange', handleVisibilityChange);
//     clearInterval(callInterval);
//   };
// }, [sipusername]);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  const [isMicMuted, setIsMicMuted] = useState(false);
  const handleMicClick = () => {
  setIsMicMuted(!isMicMuted);
  if (client) {
    const activeCall = client.activeCall;
    
    // alert(call.activeCall)
    if (call) {
      if (isMicMuted) {
        call.unmuteAudio();

      } else {
        call.muteAudio();

      }
    }
  }
};
  // useEffect(() => {
  //   document.body.style.backgroundColor = "rgb(42,45,54)";
  //   const fetchUserData = async () => {
  //     const id = sessionStorage.getItem("login");
      
  //     if (id) {
  //       try {
  //         // const {data: { firstname, lastname,email,profile,balance,apikey }} = await axios.post(`${API_BASE_URL}/api/userinfo`, {
  //         //   id,
  //         //   dd:sessionStorage.getItem("dd")
  //         // });
  //         const response = await axios.post(`${API_BASE_URL}/api/userinfo`, {
  //           id,
  //           dd: sessionStorage.getItem("dd"),
  //         });
  //          if (response.data.id == 5){
  //           // alert("found")
  //           handlelogoutModalOpen()
  //         }
  //         const { firstname, lastname, email, profile, balance, apikey,unread } = response.data;
  //         setFirstname(firstname)
  //         setLastname(lastname)
  //         setEmail(email)
  //         setUnread(unread)
  //         setBalance(balance)
  //         var currentPage = window.location.pathname.split("/").pop(); // Assumes the page name is the last part of the URL
  //         // alert(response.data.id)
  //       if (!balance) {
          
  //           if (currentPage === 'chats' || currentPage === 'campaign') {
              
  //             handleModalOpen()
  //           }
            
  //           // Call setBalance function with the current balance
  //           // setBalance(balance);
  //       }
    
  //         const imageUrl = 'http://prophonedev.com/static/media/'  + profile;
  //         setProfileimage(imageUrl)
  //         setCheckimagename(profile)
          
  //       } catch (error) {
          
  //       }
  //     } else {
  //       navigate("/login");
  //     }
  //   };
  
  //   fetchUserData(); 
  // }, []);
  const logout = async (e) =>  {
// localStorage.removeItem("login");
    
  const respon = await axios.post(`${API_BASE_URL}/api/logout`, {
    device:sessionStorage.getItem("dd")
  });
sessionStorage.removeItem("login");
sessionStorage.removeItem("dd");
navigate("/login");

  };
  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  if (!setAuthenticated) {
    // alert('if');
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    // alert('else');
    return (
        <>
  <Modal show={incommingcallmodal} contentClassName="custom-modal-content" onHide={handleincommingcallModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
     

        <Modal.Body>
        
         
            <div className="text-center">
            <img src={phone} style={{height:'70px'}} className='mb-4'></img>

            <h4 style={{textDecoration:'underline'}}>{formatPhoneNumber(caller)}</h4>
            <span>
      {!acceptcall
        ? 'is calling'
        : callstatus === 'active'
        ? formatDuration(callDuration)
        : 'Connecting...'}
    </span>
          
            <br></br>
            <br></br>
            {!acceptcall ? (
              <div className="mt-4 mb-4" style={{display:'flex',justifyContent:'center'}}>
            <button class="btn btn-danger" onClick={handleDecline} style={{width:'23% '}} ><FaPhone style={{transform: 'rotate(225deg)'}}></FaPhone>&nbsp;&nbsp;Decline</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button class="btn btn-success" onClick={handleAccept}><FaPhone style={{transform: 'rotate(90deg)'}}></FaPhone>&nbsp;&nbsp;Accept</button>
            </div>
          ) :(
            <>
            <button
        className={`btn float-left ${isMicMuted ? 'btn-secondary' : 'btn-success'}`}
        style={{ width: '55px',margin:'0 auto' }}
        onClick={handleMicClick}
      >
        {isMicMuted ? (
          <FaMicrophoneSlash className="fs-5" />
        ) : (
          <FaMicrophone className="fs-5" />
        )}
      </button>&nbsp;&nbsp;&nbsp;

    <button class="btn btn-danger float-right" onClick={handleDecline} style={{width:'55px',margin:'0 auto'}}><FaPhone className="fs-5" style={{transform: 'rotate(225deg)'}}></FaPhone></button>
    </>
          )}
      
           
            </div>
           
        
        </Modal.Body>
      {/* <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-primary cancel_btn" onClick={handleincommingcallModalClose} data-dismiss="modal"  >GOT IT</button>
        </Modal.Footer> */}
     
      </Modal>
      <audio src={Ringtone} ref={audioRef}></audio>
      <audio src={Hangup} ref={HangupRef}></audio>
<audio ref={remoteAudioRef} autoPlay></audio>

     <Modal show={showlogoutModal} contentClassName="custom-modal-content" onHide={handlelogoutModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
     

        <Modal.Body>
        <div className="text-center">
            <img src={alerticon} style={{height:'50px'}} className='mb-4'></img>

            <h4>YOU HAVE BEEN LOGOUT</h4>
        </div>
        <div className="modal-body text-center">
            <p className=''>Someone has logged in as this user from a different Device.<br></br>
            You're allowed to login only on single web and mobile device</p>
     
              
          
          
          </div>
        </Modal.Body>
      <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-primary cancel_btn" onClick={handlelogoutModalClose} data-dismiss="modal"  >GOT IT</button>
        </Modal.Footer>
     
      </Modal>
      <Modal show={showupgradeModal} contentClassName="custom-modal-content" onHide={handleupgradeModalclose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
       
        <Modal.Body>
        
        <div className="text-center">
            <h4>PLEASE UPGRADE YOUR  ACCOUNT TO ACCESS THIS FEATURE</h4>
        </div>
        {/* <div className="modal-body ">
            
        <div className="col-12" >
    <iframe width="440" height="299" src="https://www.youtube.com/embed/iSYeb4e-e18?si=jnZWJquIOKcHUS7L" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
  </div>
           
          </div> */}
        </Modal.Body>
      
       
      </Modal>
   <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
       
        <Modal.Body>
        
        <div className="text-center">
            <h4>PLEASE CONNECT YOUR TELNYX ACCOUNT TO ACCESS THIS FEATURE</h4>
        </div>
        <div className="modal-body ">
            
        <div className="col-12" >
    <iframe width="440" height="299" src="https://www.youtube.com/embed/iSYeb4e-e18?si=jnZWJquIOKcHUS7L" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
  </div>
           
          </div>
        </Modal.Body>
      
       
      </Modal>
      {isuerloggedin && (
        <div>
        <div class="overlay-container">
          Back to Admin: &nbsp;&nbsp;
          <button type="button" class="logout-button" onClick={logoutadmin}>Log Out</button>
        </div>
      </div>
      )}
      

   <Navbar  expand="lg" className="fixed-top mt-4" style={{zIndex: '999', paddingRight: '50px' }}>
   {/* <div className="container-fluid" style={{float:'right',justifyContent:'end'}}>
      <div className="row">
        <div className="col-7 ml-auto">
          <img src={banner}></img>
        </div>
        <div class=" float-right d-flex justify-content-end">
        <div class="mt-3">
        <Link to="/chats" class="inbox-btn  position-relative">
          
          <FaInbox/>&nbsp;&nbsp;
    <span style={{}} class="ib-coutner-alert position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
      {unread}
    </span>&nbsp;&nbsp;
  </Link>&nbsp;&nbsp;&nbsp;
          &nbsp;<FaCalendar/>&nbsp;&nbsp;&nbsp;
          <FaBell/>&nbsp;&nbsp;&nbsp;
          </div>
          {balanced && (<li className="nav-item mt-2" id="balance_show" style={{ color: '#fff', paddingRight: '12px', fontStyle: 'italic',listStyle:'none' }}>
      <p style={{ margin: '0px' }}><b>Telnyx Balance</b></p>

            <p style={{ margin: '0px' }}>{balanced ? `$${balanced}` : '0.00$'} </p>
          </li>
          )}
        <NavDropdown title={
        
        chkimagename ? <img 
          src={profileimage}
           style={{width:'2.375rem', height: '2.375rem'}} alt=""
            class=" rounded-circle mt-2"/> : <FaUserCircle size={40}></FaUserCircle>
        }
                                id="basic-nav-dropdown" className="ml-auto">
        
        <li>
                      <a class="dropdown-item" href="javascript:void(0)">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar avatar-online">
                            {
        
        chkimagename ? <img 
          src={profileimage}
           style={{width:'2.375rem', height: '2.375rem'}} alt=""
            class=" rounded-circle"/> : <FaUserCircle size={40}></FaUserCircle>
        }
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <span class="fw-medium d-block">{firstname} {lastname}</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <NavDropdown.Divider />
                    <Link to="/settings" className="head-dropdown mb-2"><FaUserCircle style={{marginLeft:'10px',marginRight:'8px'}} className="mr-2"/> Profile</Link>
          <br></br>
          <Link to="/settings"  className="head-dropdown mt-3"><FaCog style={{marginLeft:'10px',marginRight:'8px'}}/> Settings</Link>
          <NavDropdown.Divider />
          <NavDropdown.Item to="/login" onClick={logout}><FaSignOutAlt style={{marginLeft:'10px',marginRight:'8px'}} /> Logout</NavDropdown.Item>
        </NavDropdown>
        </div>
        </div>
    </div> */}
    
   <div className="container-fluid d-flex justify-content-between align-items-center">
    <div className="d-flex align-items-center">
    <Link target="_blank" to="http://leads.getbatch.co/mztQkPN"> <img src={banner} alt="Banner" className="img-fluid" /></Link>
    </div>  
    <div className="d-flex align-items-center">
    <Link to="/chats" class="inbox-btn  position-relative" style={{marginRight: '15px'}}>
          
          <FaInbox/>&nbsp;&nbsp;
    <span style={{}} class="ib-coutner-alert position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
      {unread}
    </span>&nbsp;&nbsp;
  </Link>
      <FaCalendar className="me-3" />
      <FaBell className="me-3" />
      {balanced && (
        <div className="nav-item mt-2 text-white me-3" style={{ fontStyle: 'italic' }}>
          <p className="mb-0"><b>Telnyx Balance</b></p>
          <p className="mb-0">{balanced ? `$${balanced}` : '0.00$'}</p>
        </div>
      )}
      <NavDropdown
        title={
          chkimagename ? (
            <img
              src={profileimage}
              alt="Profile"
              className="rounded-circle mt-2"
              style={{ width: '2.375rem', height: '2.375rem' }}
            />
          ) : (
            <FaUserCircle size={40} />
          )
        }
        id="basic-nav-dropdown"
        className="ml-auto"
      >
        <li>
          <a className="dropdown-item" href="javascript:void(0)">
            <div className="d-flex">
              <div className="flex-shrink-0 me-3">
                <div className="avatar avatar-online">
                  {chkimagename ? (
                    <img
                      src={profileimage}
                      alt="Profile"
                      className="rounded-circle"
                      style={{ width: '2.375rem', height: '2.375rem' }}
                    />
                  ) : (
                    <FaUserCircle size={40} />
                  )}
                </div>
              </div>
              <div className="flex-grow-1">
                <span className="fw-medium d-block">{firstname} {lastname}</span>
              </div>
            </div>
          </a>
        </li>
        <NavDropdown.Divider />
        <Link to="/settings" className="head-dropdown mb-2"><FaUserCircle className="me-2" /> Profile</Link>
        <br />
        <Link to="/settings" className="head-dropdown mt-3"><FaCog className="me-2" /> Settings</Link>
        <NavDropdown.Divider />
        <NavDropdown.Item to="/login" onClick={logout}><FaSignOutAlt className="me-2" /> Logout</NavDropdown.Item>
      </NavDropdown>
    </div>
  </div>

  {/* <Container style={{marginLeft: '100vh'}}>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    
    <Navbar.Collapse className="justify-content-end">
   
      <Nav className="justify-content-end ">
        <div  className="nav-item mt-3 mr-4 fs-5">
        <Link to="/chats" class="inbox-btn  position-relative">
          
        <FaInbox/>&nbsp;&nbsp;
  <span style={{}} class="ib-coutner-alert position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
    {unread}
  </span>&nbsp;&nbsp;
</Link>&nbsp;&nbsp;&nbsp;
        &nbsp;<FaCalendar/>&nbsp;&nbsp;&nbsp;
        <FaBell/>&nbsp;&nbsp;&nbsp;
        </div>
      {balanced && (<li className="nav-item mt-2" id="balance_show" style={{ color: '#fff', paddingRight: '12px', fontStyle: 'italic' }}>
      <p style={{ margin: '0px' }}><b>Telnyx Balance</b></p>

            <p style={{ margin: '0px' }}>{balanced ? `$${balanced}` : '0.00$'} </p>
          </li>
          )}
        <NavDropdown title={
        
        chkimagename ? <img 
          src={profileimage}
           style={{width:'2.375rem', height: '2.375rem'}} alt=""
            class=" rounded-circle"/> : <FaUserCircle size={40}></FaUserCircle>
        }
                                id="basic-nav-dropdown" className="ml-auto">
        
        <li>
                      <a class="dropdown-item" href="javascript:void(0)">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar avatar-online">
                            {
        
        chkimagename ? <img 
          src={profileimage}
           style={{width:'2.375rem', height: '2.375rem'}} alt=""
            class=" rounded-circle"/> : <FaUserCircle size={40}></FaUserCircle>
        }
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <span class="fw-medium d-block">{firstname} {lastname}</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <NavDropdown.Divider />
                    <Link to="/settings" className="head-dropdown mb-2"><FaUserCircle style={{marginLeft:'10px',marginRight:'8px'}} className="mr-2"/> Profile</Link>
          <br></br>
          <Link to="/settings"  className="head-dropdown mt-3"><FaCog style={{marginLeft:'10px',marginRight:'8px'}}/> Settings</Link>
          <NavDropdown.Divider />
          <NavDropdown.Item to="/login" onClick={logout}><FaSignOutAlt style={{marginLeft:'10px',marginRight:'8px'}} /> Logout</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  </Container> */}
</Navbar>
{/* <div class="floating-button-container">
 <div class="row">
 <div class="col-md-12">
 <div class="card call-card"  >
  <div class="card-header">
    Featured
  </div>
  <div class="card-body">
  <ul style={{ padding: '0px 7px 0px 9px'}}>
    <li class="list-group-item mb-2">Dallas Reynolds You</li>
    <li class="list-group-item ">(615) 744-9559</li>
    
  </ul>
  </div>
  <div class="card-footer">
    <div style={{display:'flex',justifyContent: 'end'}}>
    <button
        className={`btn float-right ${isClicked ? 'btn-secondary' : 'btn-success'}`}
        style={{ width: '55px' }}
        onClick={handleClick}
      >
        {isClicked ? (
          <FaMicrophoneSlash className="fs-5" />
        ) : (
          <FaMicrophone className="fs-5" />
        )}
      </button>
    <button class="btn btn-default float-right" style={{width:'55px'}}><FaPhone style={{transform: 'rotate(225deg)'}}></FaPhone></button>

    <button class="btn btn-danger float-right" style={{width:'55px'}}><FaPhone className="fs-5" style={{transform: 'rotate(225deg)'}}></FaPhone></button>
  </div>

  </div>
</div>
 </div>
 <div class="col-md-6">

  {/* <div class="container-dial">
    <div id="output"></div>
    <div class="row-dial">
      <div class="digit" id="one">1</div>
      <div class="digit" id="two">2
        <div class="sub">ABC</div>
      </div>
      <div class="digit" id="three">3
        <div class="sub">DEF</div>
      </div>
    </div>
    <div class="row">
      <div class="digit" id="four">4
        <div class="sub">GHI</div>
      </div>
      <div class="digit" id="five">5
        <div class="sub">JKL</div>
      </div>
      <div class="digit">6
        <div class="sub">MNO</div>
      </div>
    </div>
    <div class="row">
      <div class="digit">7
        <div class="sub">PQRS</div>
      </div>
      <div class="digit">8
        <div class="sub">TUV</div>
      </div>
      <div class="digit">9
        <div class="sub">WXYZ</div>
      </div>
    </div>
    <div class="row">
      <div class="digit">*
      </div>
      <div class="digit">0
      </div>
      <div class="digit">#
      </div>
    </div>
    <div class="botrow"><i class="fa fa-star-o dig" aria-hidden="true"></i>
      <div id="call"><i class="fa fa-phone" aria-hidden="true"></i></div>
      <i class="fa fa-long-arrow-left dig" aria-hidden="true"></i>
    </div>
  </div> */}
  {/* </div>
</div>
</div> */}
 {/* <div class="floating-button-container">
        <button class="floating-button" onClick={handleModalOpen}><FaPhone className="phone-right"></FaPhone></button>
    </div> */}
    </>
    );
  }
}

export default Header;
