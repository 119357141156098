import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate,useLocation  } from 'react-router-dom';

import '../css/loginform.css';
// import { useState } from "react";
import logo from '../icons/logo.png';
import mailicon  from '../icons/email-icon.png';
import passwordpng from '../icons/pass.png';
import API_BASE_URL from '../components/config';

function ForgetForm() {
  const [processing, setProcessing] = useState(false);
 
 
  const location = useLocation();

    
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [codesent, setCodeSent] = useState(false);
  const [emailverify, setEmailverify] = useState(false);
  const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' });
  const [disableResend, setDisableResend] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [code, setCode] = useState('');
  const [coderesent, setCoderesent] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const phone = searchParams.get('phone');
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
   
    document.body.style.backgroundColor = 'white'; 
  }, []);
  const handleResendClick = async (e) => {
    setCoderesent(false);

    setDisableResend(true);
   
    setCountdown(30); 
    let interval = setInterval(() => {
        setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
            clearInterval(interval);
            setDisableResend(false);
            return 5;
        }
        return prevCountdown - 1;
        });
    }, 1000);
    try {

      // Send the email and verification code to the backend
      const response = await axios.post(`${API_BASE_URL}/api/forget-password`, { email });

      // alert(response.data)
          if (response.data === 1) {
      
            setCoderesent(true);
            
            setProcessing(false)
    
          } 
        } catch (error) {
          setError("Please Enter Correct Code  ")
          setProcessing(false)
    
        }
  };
  const validatacode = async (e) => {
    setProcessing(true)
    e.preventDefault();
    
     
        try {

      const response = await axios.post(`${API_BASE_URL}/api/validate-code`, { code });

          if (response.data === 1) {
            setEmailverify(true);

            setProcessing(false)
          } else if (response.data === 0) {
            setError('Incorrect Code');
            setProcessing(false)
          } else  {
            setError('Incorrect Code');
            setProcessing(false)
          }
        } catch (error) {
            setProcessing(false)
          console.error('Error sending verification code:', error);
        }
      

    
    
  };
  const handleSubmit = async (e) => {
    setProcessing(true)
    e.preventDefault();
    
     
        try {

      const response = await axios.post(`${API_BASE_URL}/api/forget-password`, { email });

          if (response.data === 1) {
            setError('');

            setCodeSent(true);
            setProcessing(false)
            setDisableResend(true);
    if (disableResend) {
        setCountdown(30); // Reset countdown to 30 before starting the interval
        let interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown === 1) {
              clearInterval(interval);
              setDisableResend(false);
              return 30;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }
          } else if (response.data === 0) {
            setError('No User Found !');
            setProcessing(false)
          
          }
        } catch (error) {
            setProcessing(false)
          console.error('Error sending verification code:', error);
        }
      

    
    
  };
  const handlePassChange = (e) => {
    const { name, value } = e.target;
    
    setPasswords(prevPasswords => ({
      ...prevPasswords,
      [name]: value
    }));
   

  };
  
  const handlePassSubmit = async (e) => {
    e.preventDefault();
    if (passwords.password !== passwords.confirmPassword) {
        setSuccessMessage('')

      setError('Password do not match');


    
  }else {
    // setPaymentpage(true)
    setError('');
    try {
        const password = passwords.password
        const response = await axios.post(`${API_BASE_URL}/api/update-password`, { email,password });
        setSuccessMessage('Password Updated Succesfully !')
            if(phone){
              window.location.href = "https://app.prophone.io/success?password=your_password";

            }else{
              setTimeout(function() {
                navigate("/login")
            }, 3000);
            }
           
          } catch (error) {
              setProcessing(false)
            console.error('Error sending verification code:', error);
          }
      
    }
  };
  return (
    <section className="bg-white ">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className='authentication-wrapper authentication-basic container-p-y'>
        <div className='authentication-inner py-4'>
        
        <div className="card  login-card" id="login-card">
        {!codesent &&(<div className="card-body p-3 p-md-4 p-xl-5">
            <div className="text-center mb-3">
            <img src={logo} style={{width: '60%'}}></img>
            <div>
            

            </div>
            </div>
            {/* <h4 class="mb-1 pt-2 " id="color-loginform">Enter Your Email</h4> */}
            <p class="mb-4 " id="color-loginform">Please Enter your email</p>
            <form onSubmit={handleSubmit}>
              <div className="row gy-2 overflow-hidden">
                <div className="col-12 mt-1">
                {/* {error && (<p className="alert alert-danger mt-2" style={{color:'#fff'}}>{error}</p>)} */}
                
                
                <label  htmlFor="email" className='form-label' id="color-loginform">Email</label>

                  <div className="form-floating mb-3">

                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className=" " name="email" id="email-pass-input" placeholder="name@example.com" required/>
                  </div>
                </div>
                <div className="col-12">
               
                {error && <div className="col-12 text-danger">{error}</div>}

                </div>
                
                <div className="col-12">
                  <div className="d-grid my-3">
                  <button className="btn btn-primary ing-reg-btn" type="submit"disabled={processing}>{processing ? 'Submitting...' : 'Submit'} </button>
                  </div>
                </div>
               
    
{!phone && (<div className="col-12">
    <p className="m-0 text-secondary text-center">
         <Link to="/login" className="link-primary text-decoration-none">Go Back</Link>
    </p>
</div>)}
              </div>
            </form>
          </div>)}
          {codesent && !emailverify &&(<div className="card-body p-3 p-md-4 p-xl-5">
            <div className="text-center ">
            <img src={mailicon} style={{width: '60%'}}></img>
            </div>
            <h4 class="mb-1 pt-2 " id="color-loginform">Check your email</h4>
            <p class="mb-4 " id="color-loginform">Enter  the 6-digit code we just sent to {email} to continue</p>
            <form onSubmit={validatacode}>
              <div className="row gy-2 overflow-hidden">
                
                <div className="col-12">

                  <div className="form-floating mb-3">
                    <input value={code} onChange={(e) => setCode(e.target.value)} type='text' className=" " name="code" id="email-pass-input" placeholder="6-digit code" required/>
                  </div>
                  {/* {error && (<p className="alert alert-danger mt-2" style={{color:'#fff'}}>{error}</p>)} */}
                </div>
                {error && <div className="col-12 text-danger">{error}</div>}
                <div className="col-12">
                  <div className="d-grid my-3">
                    <button className="btn btn-primary ing-reg-btn" type="submit"disabled={processing}>{processing ? 'Submitting...' : 'Submit'} </button>
                  </div>
                </div>
                <div className="col-12">
          <p className="m-0 text-secondary text-center">
          {coderesent &&(<p id="color-loginform">Code resent succesfully !</p>)}
          <button type="button" onClick={handleResendClick} className="btn btn-default text-decoration-none" disabled={disableResend}>
  {disableResend ? `Resend code in ${countdown}s` : 'Resend code'}
</button>

          </p>
        </div>
              
              </div>
            </form>
          </div> 
  )}
   {emailverify && (
  <div className="card-body p-3 p-md-4 p-xl-5">
    <div className="text-center mb-3">
      <div className="text-center ml-2" style={{ width: '60%', fontSize: '45px', marginLeft: '55px' }}><img src={passwordpng} style={{width: '60%'}}></img></div>
    </div>
    <h4 className="mb-1 pt-2 mb-3" id="color-loginform text-center">New Password</h4>
    
    <form onSubmit={handlePassSubmit}>
      <div className="row gy-2 overflow-hidden">
        <div className="col-12">
          <div className="form-floating mb-3">
            <input 
              type='password' 
              name="password" 
              // className='form-control'
              placeholder="Your password" 
              value={passwords.password}
              id ="email-pass-input"
              onChange={handlePassChange}
              required
            />
            <label htmlFor="password-input">Password</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating mb-3">
            <input 
            id ="email-pass-input"
              type='password' 
              name="confirmPassword" 
              placeholder="Confirm password" 
              value={passwords.confirmPassword}
              onChange={handlePassChange}
              required
            />
            <label htmlFor="confirm-password-input">Confirm Password</label>
          </div>
        </div>
        {error && <div className="col-12 text-danger">{error}</div>}
        {successMessage && <div className="col-12 text-success">{successMessage}</div>}
        <div className="col-12">
          <div className="d-grid my-3">
            <button 
              className="btn btn-primary ing-reg-btn" 
              type="submit" 
              
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
)}
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>
  );
}

export default ForgetForm;
