
import React, { useState,useEffect } from 'react';
import { Link,useNavigate,useLocation  } from 'react-router-dom';
import '../css/loginform.css';
import card  from '../icons/card.png';
import PaymentForm from  '../components/paymentform';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51NcEOuHYDnVieE8qBDQDi9KA2qOYbL12Tc5FsfBmhdFaBVv6Iw74JD41SbnN9K7QqvOGCVnBHDnm0Z5RPlBmAjXz00uGQnoN3U');
// const stripePromise = loadStripe('pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl');

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI here
      return <h1>Something went wrong. Please try again later.</h1>;
    }

    return this.props.children;
  }
}

function MobilePaymentpage({ email, password, phone,plan }) {
  // alert('payment page',email, phone)
    // useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const response = await axios.post('http://127.0.0.1:5000/secret/', {
    //           amount: 1000, // Amount in cents
    //           currency: 'usd',
    //         });
    //         console.log(response.data);
    //       } catch (error) {
    //         console.error('Error:', error);
    //       }
    //     };
      
    //     fetchData(); // Call the async function
    //   }, []); // Empty dependency array to ensure the effect runs only once
      

 
  const location = useLocation();
 

  return (
    <section className="bg-white ">
  <div className="container">
    <div className="row ">
      <center><div className="">
      <img src={card} style={{width: '13%'}}></img>
      <h4 class="mb-2  " id="color-loginform">Billing details</h4>
            <p class="mb-3  " id="color-loginform">Enter you billing address to kick start your <strong>7-day free trial </strong>,Dont<br></br> worry, we'll send you a reminder email on day before your plan<br></br>starts and your card is due to be charged</p>
</div></center>
<div class="container center">
  <div class="row justify-content-center">
    
    <div class="col-md-6">
    <ErrorBoundary>
    <Elements stripe={stripePromise}>
    <PaymentForm email={email} password={password} phone={phone} plan={plan} />

            {/* {google ? (
                <PaymentForm email={email} phone={phone} />
            ) : (
                <PaymentForm email={email} password={password} phone={phone} />
            )} */}
        </Elements>
  </ErrorBoundary>
    
    </div>
      </div>
    </div>
  </div>
  </div>
  


  
  
</section>
  );
}

export default MobilePaymentpage;
