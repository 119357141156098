import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../css/loginform.css';
import API_BASE_URL from '../components/config';
import logo from '../icons/logo.png';
import google from '../icons/google.png';
import alerticon from '../icons/alert-icon.png';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Modal, Button } from 'react-bootstrap'

import { auth, googleProvider } from "./firebase";
import { signInWithPopup, signInWithRedirect, getRedirectResult, signInAnonymously } from "firebase/auth"; // Import directly from firebase/auth

function LoginForm() {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');

  const [showModal, setShowModal] = useState(false);


  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const [showlogoutModal, setShowlogoutModal] = useState(false);


  const handlelogoutModalOpen = () => setShowlogoutModal(true);
  const handlelogoutModalClose = () => setShowlogoutModal(false);

  const [user, setUser] = useState(null);

  const checkGoogleSignIn = async () => {
    try {
      // getRedirectResult will return the result only after the redirect flow is completed
      const result = await signInWithPopup(auth);
      console.log("Redirect result:", result); // Check if result is null

      if (result) {
        // If the user signed in successfully, you can get user info here
        const user = result.user;
        console.log("User email:", user.email); // Access user email
      } else {
        console.log("No result from redirect, it might be null.");
      }
    } catch (error) {
      console.error("Error getting redirect result:", error);
    }
  };

  useEffect(() => {
    checkGoogleSignIn(); // Call the function to check for Google sign-in result after redirect
  }, []);

  // Function to handle Google Sign-In
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // const user = result.user;
      console.log("Signing: ", result);
      
    } catch (error) {
      console.error("Error signing in with Google.: ", error.message);
    }
  };
  // const handleGoogleSignIn = async () => {
  //   try {
  //     // This only initiates the Google sign-in process and redirects to the Google login page
  //     await signInWithRedirect(auth, googleProvider);
  //     // No need to handle `result` here, as it will be available after the redirect
  //   } catch (error) {
  //     console.error("Error during sign-in redirect:", error);
  //   }
  // };
  const generateFingerprint = async () => {
    const fp = await FingerprintJS.load();

    // Get the visitor identifier when you need it.
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    // alert(visitorId)
    document.body.style.backgroundColor = "rgb(42,45,54)";
    return visitorId
  }
  const googlelogin = async (credentialResponse) => {
    const decodedCredential = jwtDecode(credentialResponse.credential);
    const userEmail = decodedCredential.email;
    setProcessing(true)
    // e.preventDefault();
    try {
      const fp = await FingerprintJS.load();

      // Get the visitor identifier when you need it.
      const result = await fp.get();

      // This is the visitor identifier:
      const visitorId = result.visitorId;
      const response = await axios.post(`${API_BASE_URL}/api/login/`, {
        email: userEmail,
        device: visitorId,
      });
      if (response.data.id) {
        setSuccessMessage('Login successful');
        // localStorage.setItem("login", response.data.id)
        sessionStorage.setItem("login", response.data.id);


        navigate("/");
      } else if (response.data == 5) {
        handleModalOpen()
        setProcessing(false)

      }
      else {
        setError('Incorrect Credentials');
        setProcessing(false)

      }

    } catch (error) {
      setProcessing(false)

      setError('Login failed');
    }
  }


  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated") || false));

  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const id = sessionStorage.getItem("login");
    // alert(id)
    if (id) {
      navigate('/')
    }
    document.body.style.backgroundColor = 'white';
  }, []);
  const usehere = async (e) => {
    setProcessing(true)
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;
    try {
      const response = await axios.post(`${API_BASE_URL}/api/device`, {
        email,

        device: visitorId,
      });
      setProcessing(false)

      sessionStorage.setItem("login", response.data.id);
      sessionStorage.setItem("dd", visitorId);

      navigate("/");

    } catch (error) {
      alert("error")
    }

  }
  const handleSubmit = async (e) => {
    setProcessing(true)
    e.preventDefault();
    try {
      const fp = await FingerprintJS.load();

      // Get the visitor identifier when you need it.
      const result = await fp.get();

      // This is the visitor identifier:
      const visitorId = result.visitorId;
      // alert(visitorId)
      const response = await axios.post(`${API_BASE_URL}/api/login/`, {
        email,
        password,
        device: visitorId,
      });
      if (response.data.id) {
        setSuccessMessage('Login successful');
        // localStorage.setItem("login", response.data.id)
        sessionStorage.setItem("login", response.data.id);
        sessionStorage.setItem("dd", response.data.dd);

        navigate("/");
      } else if (response.data == 2) {
        setError('Incorrect Password');
        setProcessing(false)

      } else if (response.data.reason) {
        setError('Account  Suspended');
        setReason(response.data.reason)
        setProcessing(false)
      } else if (response.data == 5) {
        handleModalOpen()
        setProcessing(false)

      }
      else {
        setError('Incorrect Credentials');
        setProcessing(false)

      }

    } catch (error) {
      setError('Login failed');
    }
  };

  return (

    <section className="bg-white ">
      <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>


        <Modal.Body>
          <div className="text-center">
            <img src={alerticon} style={{ height: '50px' }} className='mb-4'></img>

            <h4>Multiple Device Login</h4>
          </div>
          <div className="modal-body text-center">
            <p className=''>Your account is already active on another device.<br></br>
              Click "Use Here" to use your account on this device and logout from all other devices</p>




          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
          <button type="button" className="btn btn-primary cancel_btn" onClick={usehere} disabled={processing}>{processing ? 'Signing in...' : 'USE HERE'} </button>
          <button type="submit" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Cancel</button>
        </Modal.Footer>

      </Modal>

      <Modal show={showlogoutModal} contentClassName="custom-modal-content" onHide={handlelogoutModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>


        <Modal.Body>
          <div className="text-center">
            <img src={alerticon} style={{ height: '50px' }} className='mb-4'></img>

            <h4>YOU HAVE BEEN LOGOUT</h4>
          </div>
          <div className="modal-body text-center">
            <p className=''>Someone has logged in as this user from a different Device.<br></br>
              You're allowed to login only on single web and mobile device. To use ProPhone on multiple devices at the same time</p>




          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
          <button type="button" className="btn btn-primary cancel_btn" onClick={handlelogoutModalClose} data-dismiss="modal"  >GOT IT</button>
        </Modal.Footer>

      </Modal>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div className='authentication-wrapper authentication-basic container-p-y'>
              <div className='authentication-inner '>

                <div className="card  login-card" id="login-card">
                  <div className="card-body  p-md-4 p-xl-5">
                    <div className="text-center mb-3">
                      <img src={logo} style={{ width: '60%' }}></img>
                      <div>


                      </div>
                    </div>
                    <h4 class="mb-1 pt-2 " id="color-loginform">Welcome to ProPhone</h4>
                    <p class="mb-4 " id="color-loginform">Please sign-in to your account and start the adventure</p>
                    {/* <h2 className="fs-6 fw-normal text-center text-secondary mb-4">Sign in to your account</h2> */}
                    <form onSubmit={handleSubmit}>
                      <div className="row gy-2 overflow-hidden">
                        <div className="col-12">
                          <div className="form-floating ">
                            <button type="button" style={{
                              width: '100%',
                              padding: '6px',
                              borderRadius: '3px',
                              /* border-color: grey; */
                              backgroundColor: 'white',
                              border: '1px solid #80808063'
                            }} onClick={handleGoogleSignIn}><img style={{
                              width: '10%',
                              marginRight: ' 15px'
                            }} src={google}></img>Continue with Google</button>
                            {/* <GoogleOAuthProvider clientId="422743159001-f7k41jr4p6jvht09kan5ki97ehksqghb.apps.googleusercontent.com"> */}
                            {/* <GoogleOAuthProvider clientId="25341634720-3g0t7jtj759ku4kp8h1igd990r26nb71.apps.googleusercontent.com">
                
                                <GoogleLogin
                                className="custom-google-login"
                                onSuccess={googlelogin}
               
                onError={() => {
                  console.log('Login Failed');
                }}

               />
                 <span className="custom-google-login-text">Sign in with Google</span></GoogleOAuthProvider> */}
                          </div>
                          <br></br>
                          {reason && (
                            <>
                              <div className='alert alert-danger'>
                                <strong className='text-white'>Reason</strong>
                                <br></br>
                                <p>Your Account Has Been Suspended due to {reason}<br></br>Contact <Link>support@prophone.io</Link></p>
                              </div>
                            </>
                          )}
                          <label htmlFor="email" className='form-label' id="color-loginform">Email</label>

                          <div className="form-floating mb-1">

                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email-pass-input" placeholder="name@example.com" required />
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="password" className="form-label" id="color-loginform">Password</label>

                          <div className="form-floating mb-1">
                            <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} className=" " name="password" id="email-pass-input" placeholder="Password" required />
                          </div>
                          {error && <div className="col-12 text-danger">{error}</div>}
                          {successMessage && <div className="col-12 text-success">{successMessage}</div>}

                        </div>
                        {/* <div className="col-12">
                  <div className="d-flex gap-2 justify-content-between">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" name="rememberMe" id="rememberMe"/>
                      <label className="form-check-label text-secondary" for="rememberMe">
                        Keep me logged in
                      </label>
                    </div>
                    <a href="#!" className="link-primary text-decoration-none">Forgot password?</a>
                  </div>
                </div> */}
                        <div className="col-12">
                          <div className="d-grid my-3">
                            {!successMessage && <button className="btn btn-primary ing-reg-btn" type="submit" disabled={processing}>{processing ? 'Signing in...' : 'Sign in'} </button>}
                          </div>
                        </div>

                        <div className="col-12">
                          <p className="m-0 text-secondary text-center">
                            Don't have an account? <Link to="/signup" className="link-primary text-decoration-none">Sign up</Link>
                          </p>
                        </div>
                        <div className="col-12">
                          <p className="m-0 text-secondary text-center">
                            Forget Your Password ? <Link to="/forgetpassword" className="link-primary text-decoration-none">Click here</Link>
                          </p>
                        </div>
                        <p class="mb-4 fs-6 text-center" id="color-loginform">By continuing, you acknowledge and accept our <Link to="https://prophone.io/terms_and_conditions.html">Terms and Conditions</Link> and <Link to="https://prophone.io/privacy_policy.html">Privacy Policy</Link></p>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginForm;
