import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate,useLocation  } from 'react-router-dom';

import '../css/loginform.css';
// import { useState } from "react";
import logo from '../icons/logo.png';
import mailicon  from '../icons/email-icon.png';
import passwordpng from '../icons/pass.png';
import API_BASE_URL from '../components/config';

function Success() {
  const [processing, setProcessing] = useState(false);
 
 
  const location = useLocation();

    
  
  const searchParams = new URLSearchParams(location.search);
  const password = searchParams.get('password');
  const account = searchParams.get('account');
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
   
    document.body.style.backgroundColor = 'white'; 
  }, []);
 
  return (
    <section className="bg-white ">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className='authentication-wrapper authentication-basic container-p-y'>
        <div className='authentication-inner py-4'>
        
        <div className="card  login-card" id="login-card">

        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>
  );
}

export default Success;
