import React, { useEffect, useState,useRef } from "react";
import { Link, useNavigate,useLocation  } from 'react-router-dom';

import { FaBullhorn,FaHouseUser,FaFile,FaMobile,FaUser,FaQuestionCircle, FaCog, FaUniversity, FaSchool, FaUsers } from 'react-icons/fa';
import axios from 'axios';
import API_BASE_URL from '../components/config';

import './sidebar.css'
import pro_icon from '../icons/pro_icon.png';
import icon_text_white from '../icons/icon_text_white.png';
import Header from '../components/header'
// import '../css/theme-default.css';
function toggleSidebar() {
  
    const sidebar = document.getElementById("mySidebar");
  
    const main = document.getElementById("main");
    const navIcons = document.querySelectorAll(".nav-icon");
    const navTexts = document.querySelectorAll(".nav-text");
    sidebar.classList.toggle("collapsed");
    for (let icon of navIcons) {
      icon.classList.toggle("collapsed");
    }
    for (let text of navTexts) {
      text.classList.toggle("collapsed");
    }
    if (sidebar.classList.contains("collapsed")) {
      main.style.marginLeft = "30px";
    } else {
      main.style.marginLeft = "200px";
    }
  }
  function toggleSubMenu(event) {
    event.preventDefault();
    const submenu = event.target.nextElementSibling;
    submenu.classList.toggle("show");
  }
  
  const menuItems = [
    { title: 'Phone', path: '/phone' },
    // { title: 'DATA MANAGEMENT', path: '/data-management' },
    // { title: 'INTEGRATIONS', path: '/integerations' },
    { title: 'CRM', path: '/crm' },
    { title: 'Settings', path: '/settings' } // Adjust path as needed
  ];
// function Sidebar() {
//   const navigate = useNavigate();
//   const [authenticated, setAuthenticated] = useState(null);
//   const [isCollapsed, setIsCollapsed] = useState(false);

//   const toggleSidebar = () => {
//     setIsCollapsed(!isCollapsed);
//   };

//   useEffect(() => {
//     const loggedInUser = localStorage.getItem("login");
//     if (loggedInUser) {
//       setAuthenticated(loggedInUser);
//     } else {
//       // Redirect to login if not authenticated
//       navigate("/login");
//     }
//   }, []);

//   if (!setAuthenticated) {
//     // alert('if');
//     // Return null or loading indicator while checking authentication
//     return null;
//   } else {
//     // alert('else');
//     return (
//         <>
//         <div id="mySidebar" className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
//         <nav>
//           <a href="#" className="closebtn" onClick={toggleSidebar}>☰</a>
//           <a href="#" className="nav-icon"><FaUsers  /><span className="nav-text">Home</span></a>
//           <a href="#" className="nav-icon"><FaUsers  /><span className="nav-text">About</span></a>
//         </nav>
//       </div>

//       <div id="main" style={{ marginLeft: isCollapsed ? '50px' : '250px' }}>
//         <button className="openbtn" onClick={toggleSidebar}>☰</button>  
//         {children}
//       </div>
//   {/* <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar" >
//       <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
//         <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
//           <i className="ti ti-menu-2 ti-sm"></i>
//         </a>
//       </div>

//       <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
//         <ul className="navbar-nav flex-row align-items-center ms-auto">
//           <li className="nav-item" id="balance_show" style={{ color: '#fff', fontSize: '12px', paddingRight: '12px', fontStyle: 'italic' }}>
//             <p style={{ margin: '0px' }}><b>Telnyx Balance</b></p>
//             <p style={{ margin: '0px' }}>$00.00 <input type="hidden" id="MyBalance" value="0" /></p>
//           </li>
//           <li className="nav-item navbar-dropdown dropdown-user dropdown">
//             <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
//               <div className="avatar avatar-online">
//                 <img src="path_to_your_profile_pic"  alt="" className="h-auto rounded-circle" />
//               </div>
//             </a>
//             <ul className="dropdown-menu dropdown-menu-end">
//               <li>
//                 <a className="dropdown-item" href="javascript:void(0)">
//                   <div className="d-flex">
//                     <div className="flex-shrink-0 me-3">
//                       <div className="avatar avatar-online">
//                         <img src="path_to_your_profile_pic"  alt="" className="h-auto rounded-circle" />
//                       </div>
//                     </div>
//                     <div className="flex-grow-1">
//                       <span className="fw-medium d-block">Your Name</span>
//                     </div>
//                   </div>
//                 </a>
//               </li>
//               <li>
//                 <div className="dropdown-divider"></div>
//               </li>
//               <li>
//                 <a className="dropdown-item" href="javascript:void(0)">
//                   <i className="ti ti-user-check me-2 ti-sm"></i>
//                   <span className="align-middle">My Profile</span>
//                 </a>
//               </li>
//               <li>
//                 <a className="dropdown-item" href="javascript:void(0)">
//                   <i className="ti ti-settings me-2 ti-sm"></i>
//                   <span className="align-middle">Settings</span>
//                 </a>
//               </li>
//               <li>
//                 <div className="dropdown-divider"></div>
//               </li>
//               <li>
//                 <a className="dropdown-item" href="javascript:void(0)">
//                   <i className="ti ti-logout me-2 ti-sm"></i>
//                   <span className="align-middle">Log Out</span>
//                 </a>
//               </li>
//             </ul>
//           </li>
//         </ul>
//       </div>

//       <div className="navbar-search-wrapper search-input-wrapper d-none">
//         <input type="text" className="form-control search-input container-xxl border-0" placeholder="Search..." aria-label="Search..." />
//         <i className="ti ti-x ti-sm search-toggler cursor-pointer"></i>
//       </div>
//     </nav> */}
//   {/* <div class="split right">
//   <div class="centered">
//          <Navbar bg="dark" expand="lg" className="fixed-top" style={{ paddingRight: '250px' }}>
//       <Container>
//         <Navbar.Brand as={Link} to="/">Your Logo</Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="mr-auto">
//             <Nav.Link as={Link} to="/">Home</Nav.Link>
//             <Nav.Link as={Link} to="/about">About</Nav.Link>
//             <Nav.Link as={Link} to="/services">Services</Nav.Link>
//             <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
//           </Nav>
          
//         </Navbar.Collapse>
//         <Navbar.Collapse className="justify-content-end">
//         <Nav className="justify-content-end ml-auto">
//             <NavDropdown title={<FaUserCircle size={40} />} id="basic-nav-dropdown" className="ml-auto">
//               <NavDropdown.Item href="#profile"><FaUserCircle /> Profile</NavDropdown.Item>
//               <NavDropdown.Item href="#settings"><FaCog /> Settings</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#logout"><FaSignOutAlt /> Logout</NavDropdown.Item>
//             </NavDropdown>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar> 
//       </div>
//       </div> */}
//     </>
//     );
//   }
// }
const Sidebar = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const handleSidebarToggle = () => {
    setCollapsed(!collapsed);
  };
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showNavText, setShowNavText] = useState(false);
  const [showSecLogo, setShowSecLogo] = useState(false);
  const [numberid, setNumberid] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  // let timeoutId;
 
  
  // const handleMouseEnter = () => {
  //   setIsCollapsed(false);
  //   timeoutId = setTimeout(() => {
  //     setShowNavText(true);
  //   }, 300); 
  // };

  // const handleMouseLeave = () => {
  //   setIsCollapsed(true);
  //   setShowNavText(false);
  //   clearTimeout(timeoutId);
  // };
  const timeoutId = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutId.current);
    setIsCollapsed(false);
    timeoutId.current = setTimeout(() => {
      setShowNavText(true);
      setShowSecLogo(true); 
    }, 200);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutId.current);
    setShowSecLogo(false);
    setShowNavText(false);
    setIsCollapsed(true);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    if (!isCollapsed) {
      setShowNavText(false); // Set showNavText to false when sidebar is collapsed
    }
  };
  const [admin,setAdminLogin] = useState(false);
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      if (id == 1) {
        
        setAdminLogin(true)

       
      } 
      // const response= await axios.post(`${API_BASE_URL}/api/fetch-numbers`, {
      //   id
      // });
      // // setPurchasednumbers(response.data);
      // const firstPhoneNumber = response.data[0].id;
      // // alert(id)
      // setNumberid(firstPhoneNumber)
      
    };
  
    fetchreplies(); 
  }, []);
  return (
    <>
    <Header/>
      <div id="mySidebar" className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div className="sidebar-header mb-2" style={{marginTop: '-35px'}}>
            {/* <img src={logo} style={{width: '60%'}}></img> */}
            <Link href="/" className="nav-icon mb-1" style={{display:'flex'}}>
  <img src={pro_icon} style={{height:'25px'}}></img>
  {/* <img class="sec-logo" src="https://app.prophone.io/new_assets/logo/icon_text_white.png" style={{height: '25px', marginLeft: '5px'}} className="loginside"></img> */}
  <img src={icon_text_white}className={showSecLogo ? 'sec-logo' : 'sec-logo hidden'} style={{height: '25px', marginLeft: '5px'}}  />

</Link>
            <nav>
          {/* <button href="#" className="closebtn" onClick={toggleSidebar}>☰</button> */}
          </nav>
        

          {/* <a href="#" className="nav-icon mb-1"> 
          <img src="https://app.prophone.io/new_assets/logo/pro_icon.png" 
          style={{height:' 25px'}}></img>
           <span> <img src=
          "https://app.prophone.io/new_assets/logo/icon_text_white.png" 
          style={{height: '25px',marginLeft: '35px',marginTop:'-55px'}} class="loginside"></img></span></a> */}

          {/* <div class="app-brand demo">
            <a href="javascript:void(0)" class="app-brand-link">
              <span class="app-brand-logo demo">
                <img src="https://app.prophone.io/new_assets/logo/pro_icon.png" style={{height:' 25px'}}></img>
              </span>
              <span class="app-brand-text demo menu-text fw-bold">
                <img src="https://app.prophone.io/new_assets/logo/icon_text_white.png" style={{height: '25px',marginLeft: '10px'}}></img>
              </span>
            </a>

            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
              <i class="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
              <i class="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
            </a>
          </div>  */}

            </div>
        <nav>

        <Link to="/" className={`nav-icon mb-1 ${location.pathname === '/' ? 'active' : ''}`}> <FaHouseUser style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>Dashboard</span></Link>
        {/* <Link 
      to={`/chats/${numberid}`} 
      className={`nav-icon mb-1 ${location.pathname === `/chats/${numberid}` ? 'active' : ''}`}
    >
      <FaMobile style={{ marginRight: '10px' }} />
      <span 
        style={{ display: showNavText ? 'inline-block' : 'none' }} 
        className={`nav-text ${showNavText ? 'show' : ''}`}
      >
        Phone
      </span>
      </Link> */}
      <Link
  to='/chats'
  className={`nav-icon mb-1 ${location.pathname.startsWith('/chats') ? 'active' : ''}`}
>
  <FaMobile style={{ marginRight: '10px' }} />
  <span
    style={{ display: showNavText ? 'inline-block' : 'none' }}
    className={`nav-text ${showNavText ? 'show' : ''}`}
  >
    Phone
  </span>
</Link>
<Link to="/campaign" className={`nav-icon mb-1 ${location.pathname === '/campaign' ? 'active' : ''}`}> <FaBullhorn style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>Campaign</span></Link>

        {/* <Link to='/chats' className={`nav-icon mb-1 ${location.pathname === '/chats' ? 'active' : ''}`}> <FaMobile style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>Phone</span></Link> */}
        {admin && (<Link to="/users" className={`nav-icon mb-1 ${location.pathname === '/users' ? 'active' : ''}`}> <FaUser style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>Users</span></Link>)}
        <Link to="/crm" className={`nav-icon mb-1 ${location.pathname === '/crm' ? 'active' : ''}`}> <FaUsers style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>CRM</span></Link>
          {/* <a href="#" className="nav-icon mb-1"><FaUsers style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>Users</span></a> */}
        {admin && (<Link to="/notifications" className={`nav-icon mb-1 ${location.pathname === '/notifications' ? 'active' : ''}`}> <FaBullhorn style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>Notifications</span></Link>)}
         
          {admin && (<Link to="/support-center"  className={`nav-icon mb-1 ${location.pathname === '/support-center' ? 'active' : ''}`}> <FaQuestionCircle style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} class={`nav-text ${showNavText ? 'show' : ''}`} as={Link} to="/support-center">Support</span></Link>)}
          <Link to="/settings"  className={`nav-icon mb-1 ${location.pathname === '/settings' ? 'active' : ''}`}> <FaCog style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} class={`nav-text ${showNavText ? 'show' : ''}`} as={Link} to="/settings">Settings</span></Link>
          <Link target="_blank" to="https://www.youtube.com/@ProPhoneInc" > <FaUniversity style={{ marginRight: '10px' }}  /><span style={{ display: showNavText ? 'inline-block' : 'none' }} className={`nav-text ${showNavText ? 'show' : ''}`}>University</span></Link>
        
          {/* {menuItems.map((item, index) => (
            <li key={index} className={
              (location.pathname === item.path   )? 'active' : ''
            }>
              <Link to={item.path} className="nav-icon" title={item.title}>{item.title}</Link>
            </li>
          ))} */}
        
      
        </nav>
      </div>

      <div id="main" style={{ marginLeft: isCollapsed ? '50px' : '250px' }}>
      <div className="container-fluid">
        <div className="row">
        
        <div className="col-12">
            {children}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Sidebar;
// export default Sidebar;
