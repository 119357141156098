// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import { Link,useNavigate,useLocation  } from 'react-router-dom';
// import '../css/loginform.css';
// import card  from '../icons/card.png';
// import PaymentForm from  '../components/paymentform';
// // import { PaymentElement } from '@stripe/react-stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe('pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl');

// // import { AuthProvider } from '../components/AuthContext';

// // import { useAuth } from '../components/AuthContext';
// function Paymentpage() {
//     // useEffect(() => {
//     //     const fetchData = async () => {
//     //       try {
//     //         const response = await axios.post('http://127.0.0.1:5000/secret/', {
//     //           amount: 1000, // Amount in cents
//     //           currency: 'usd',
//     //         });
//     //         console.log(response.data);
//     //       } catch (error) {
//     //         console.error('Error:', error);
//     //       }
//     //     };
      
//     //     fetchData(); // Call the async function
//     //   }, []); // Empty dependency array to ensure the effect runs only once
      
     
//     const [countries, setCountries] = useState([]);
 
//   const navigate = useNavigate();
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const email = searchParams.get('email');
//   const [disableResend, setDisableResend] = useState(true);
//   const [countdown, setCountdown] = useState(30);
//   const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));

//   const [successMessage, setSuccessMessage] = useState('');
//   const options = {
//     clientSecret:'pi_3PBgm1HYDnVieE8q08jyxD1C_secret_mLZDI58laR5w2MTOZ3cPa792x',
//     mode: 'subscription',
//   };
  
// //   const elements = stripe.elements({ clientSecret });
// //   const optionsh = { mode: 'billing' };

// //   const addressElement = elements.create('address', optionsh);
// //   const paymentElement = elements.create('payment');
// //   addressElement.mount('#address-element');
// //   paymentElement.mount('#payment-element');

//   return (
//     <section className="bg-white ">
//   <div className="container">
//     <div className="row justify-content-center">
//       <center><div className="col-12 mt-3 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
//       <img src={card} style={{width: '13%'}}></img>
//       <h4 class="mb-2  " id="color-loginform">Billing details</h4>
//             <p class="mb-3  " id="color-loginform">Enter you billing address to kick start your <strong>7-day free trial </strong>,Dont<br></br> worry, we'll send you a reminder email on day before your plan<br></br>starts and your card is due to be charged</p>
// </div></center>
// <div class="container center">
//   <div class="row justify-content-center">
    
//     <div class="col-md-6">
//     <Elements  
//                   stripe={stripePromise}>
//       <PaymentForm  />
//     </Elements>
//     </div>
//       </div>
//     </div>
//   </div>
//   </div>
  


  
  
// </section>
//   );
// }

// export default Paymentpage;

import React, { useState,useEffect } from 'react';
import { Link,useNavigate,useLocation  } from 'react-router-dom';
import '../css/loginform.css';
import card  from '../icons/card.png';
import PaymentForm from  '../components/paymentform';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51NcEOuHYDnVieE8qBDQDi9KA2qOYbL12Tc5FsfBmhdFaBVv6Iw74JD41SbnN9K7QqvOGCVnBHDnm0Z5RPlBmAjXz00uGQnoN3U');
// const stripePromise = loadStripe('pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl');

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI here
      return <h1>Something went wrong. Please try again later.</h1>;
    }

    return this.props.children;
  }
}

function Paymentpage({ email, password, phone,plan }) {
  // alert('payment page',email, phone)
    // useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const response = await axios.post('http://127.0.0.1:5000/secret/', {
    //           amount: 1000, // Amount in cents
    //           currency: 'usd',
    //         });
    //         console.log(response.data);
    //       } catch (error) {
    //         console.error('Error:', error);
    //       }
    //     };
      
    //     fetchData(); // Call the async function
    //   }, []); // Empty dependency array to ensure the effect runs only once
      
 const isMobile = window.innerWidth < 768; // Adjust the width as needed

 
  const location = useLocation();
 

  return (
    <section className="bg-white ">
  <div className="container">
    <div className="row ">
    <center>
      <div>
        <img src={card} style={{ width: '13%' }} alt="Billing Card" />
        <h4 className="mb-2" id="color-loginform">Billing details</h4>
        <p className="mb-3" id="color-loginform">
          Enter your billing address to kick start your <strong>7-day free trial</strong>. Don’t worry, we'll send you a reminder email a day before your plan starts, and your card is due to be charged.
        </p>
      </div>
    </center>
    {/* <center><div className="">
      <img src={card} style={{width: '13%'}}></img>
      <h4 class="mb-2  " id="color-loginform">Billing details</h4>
            <p class="mb-3  " id="color-loginform">Enter you billing address to kick start your <strong>7-day free trial </strong>,Dont<br></br> worry, we'll send you a reminder email on day before your plan<br></br>starts and your card is due to be charged</p>
</div></center> */}
<div class="container center">
  <div class="row justify-content-center">
    
    <div class="col-md-6">
    <ErrorBoundary>
    <Elements stripe={stripePromise}>
    <PaymentForm email={email} password={password} phone={phone} plan={plan} />

            {/* {google ? (
                <PaymentForm email={email} phone={phone} />
            ) : (
                <PaymentForm email={email} password={password} phone={phone} />
            )} */}
        </Elements>
  </ErrorBoundary>
    
    </div>
      </div>
    </div>
  </div>
  </div>
  


  
  
</section>
  );
}

export default Paymentpage;
