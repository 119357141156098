import React, { useEffect, useState,useRef  } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../components/navbar';
import {  FaArrowDown,FaPlay,FaPause,FaReply } from 'react-icons/fa';
import moment from 'moment-timezone';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
// import 'select2/dist/js/select2.full.min';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import TimePicker from 'react-time-picker';
import API_BASE_URL from '../components/config';

import 'select2/dist/js/select2.min.js';
import loading from '../icons/loading.gif';

import axios from 'axios';
import './css/select2.min.css'; // Import the CSS file
function CreateCampaign() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
   useEffect(() => {
    // Initialize Select2 on the select element
    $('.js-example-basic-multiple').select2();
    
    // Cleanup on unmount
    return () => {
      if ($('.js-example-basic-multiple').data('select2')) {
        $('.js-example-basic-multiple').select2('destroy');
      }
    };
  }, []);
  const selectRef = useRef(null);
  const [time, setTime] = useState('12:00');

  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);
 
  const [campaignName, setCampaignName] = useState('');
  const [rateminute, setRateMinute] = useState('');
  const [audience, setAudience] = useState('');
  const [template, setTemplate] = useState('');
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [timeZone, setTimeZone] = useState('');
  const [id, setUserid] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  

  const [audiences, setAudiences] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [purchasednumbers, setNumbers] = useState([]);
  
  const [authenticated, setAuthenticated] = useState(null);

  
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/campaign-staff`, {
            id 
          });
          // setSubject(subject);
          // setMessage(message);
          // setTicketdate(date);
          // setMessages(messages);
          
            setAudiences(response.data.audience);
            setTemplates(response.data.templates);
            setNumbers(response.data.numbers);
          
          
         
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
   
  }, []);
  const handleCheckboxChange = (number, isChecked) => {
    if (isChecked) {
      setSelectedNumbers((prev) => [...prev, number]);
    } else {
      setSelectedNumbers((prev) => prev.filter((num) => num !== number));
    }
  };
  const times = [
    '12:00 AM','1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
    '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
  ];

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
    if (endTime && times.indexOf(endTime) <= times.indexOf(e.target.value)) {
      setEndTime('');
    }
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };
  const timezone_mapping = {
    'Pacific': 'America/Los_Angeles',
    'Mountain': 'America/Denver',
    'Central': 'America/Chicago',
    'Eastern': 'America/New_York'
  };
  // const filterEndTimes = () => {
  //   if (!startTime) {
  //     return times;
  //   }
  
  //   // Get the IANA timezone identifier
  //   const timeZoneIANA = timezone_mapping[timeZone];
  
  //   // Ensure that the current time is in the correct timezone
  //   const currentTime = moment().tz(timeZoneIANA); // Apply the selected timezone
  //   const currentHour = currentTime.format('h:mm A');
  
  //   // Adjust the minutes to '00' to match the time in `times` array
  //   const roundedCurrentHour = moment(currentTime).minute(0).format('h:mm A');
  
  //   const currentHourIndex = times.indexOf(roundedCurrentHour);
  //   const startIndex = times.indexOf(startTime);
  
  //   // Check if today is selected
  //   const selectedDayIsToday = selectedDays.includes(currentTime.format('dddd'));
  
  //   console.log("Selected Time Zone:", timeZone);
  //   console.log("Current Time in Timezone:", currentTime.format('h:mm A'));
  //   console.log("Rounded Current Hour:", roundedCurrentHour);
  //   console.log("Current Hour Index:", currentHourIndex);
  //   console.log("Start Time:", startTime);
  //   console.log("Start Index:", startIndex);
  //   console.log("Selected Day Is Today:", selectedDayIsToday);
  
  //   // If today is selected and the start time is earlier than or equal to current time
  //   if (selectedDayIsToday && startIndex <= currentHourIndex) {
  //     console.log("Filtering end times. Showing times after current time.");
  //     // Filter end times to be greater than the current time
  //     return times.slice(currentHourIndex + 1);
  //   }
  
  //   return times.slice(startIndex + 1);
  // };
  const filterEndTimes = () => {
    // if (!startTime) {
    //   return times;
    // }
    // const startIndex = times.indexOf(startTime);
    // return times.slice(startIndex + 1);
    return times
  };
  // Generate options based on the number of selected checkboxes
  const generateOptions = () => {
    const options = [];
    const maxOptions = 10; // Maximum number of options

    // Determine the number of options based on selectedNumbers
    const numOptions = Math.min(selectedNumbers.length * 2);

    for (let i = 1; i <= numOptions; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  };
  const handleDayChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedDays((prevSelectedDays) => [...prevSelectedDays, value]);
    } else {
      setSelectedDays((prevSelectedDays) => prevSelectedDays.filter((day) => day !== value));
    }
  };

  const handleNumberChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedNumbers((prevSelectedNumbers) => [...prevSelectedNumbers, value]);
    } else {
      setSelectedNumbers((prevSelectedNumbers) => prevSelectedNumbers.filter((number) => number !== value));
    }
  };
  const handleSubmit = async  (e) => {
    e.preventDefault();
    if (selectedDays.length === 0 || selectedNumbers.length === 0) {
      alert('Please select at least one day of the week and one phone number.');
    } else {
      const response= await axios.post(`${API_BASE_URL}/api/create-campaign`, {
        campaignName,
        audience,
        rateminute,
        template,
        timeZone,
        startTime,
        endTime,
        selectedDays,
        selectedNumbers,
        id,
      });
      alert("Campaign Created Successfuly !")
      navigate('/campaign')
      // Handle form submission
    }
  };
  const formatPhoneNumber = (phone) => {
    if (phone.startsWith("+1")) {
      return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  };
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
       
        
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'0px'}}>
          <div class="serch_bar full_width">
          <div className="tab">
          <div className={`tab-item ${location.pathname === '/campaign' || location.pathname === '/create_campaign' ? 'active' : ''}`}>
      <Link to="/campaign" className="link disabled">Campaign</Link>
      {(location.pathname === '/campaign' || location.pathname === '/create_campaign') && <div className="strip"></div>}
    </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
      
    </div>
    </div>
       

            
<div className="container  ">
{/* <div className="container  d-flex justify-content-center align-items-center"> */}
<div className="row">
<div className=" mb-2" style={{textAlign:'right'}}>
          
          {/* <Link to="/create_campaign" type="button"  className="btn btn_primary save_btn"   >
        <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Campaign
        </Link> */}
        </div>
        
    <h4 id="dash-num-color">New Campaign</h4>
    <form onSubmit={handleSubmit}>
      <div className="row mb-2">
        <div className="col-md-6">
          <div className="form-group">
            <label>Campaign Name:</label>
            <input type="text" required name="campaignname" placeholder="E.G PROMOTION CAMPAIGN" className="form-control mt-1" value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Select Audience:</label>
            <select  required name="audience" className="form-select custom-dorpdown-toglle mt-1" value={audience} onChange={(e) => setAudience(e.target.value)}>
              <option value="">Select Audience</option>
              {audiences.map((item) => (
              <option value={item.audiencename}>{item.audiencename}</option>

              ))}
              {/* <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option> */}
            </select>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          <div className="form-group">
            <label>Select SMS Template:</label>
            <select required name="template"   className="form-select custom-dorpdown-toglle mt-1" value={template} onChange={(e) => setTemplate(e.target.value)}>
              <option value="">Select Template</option>
              {templates.map((item) => (
              <option value={item._id}>{item.templatename}</option>

              ))}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group" >
            <label>Days of Week:</label>
            <div className="mt-1" style={{display:'flex'}}>
  {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
    <div key={index} className="form-check form-check-inline">
      <input
        type="checkbox"
        id={`day-${index}`}
        value={day}
        className="form-check-input"
         
        name="daysofweek" 
        checked={selectedDays.includes(day)}
                    onChange={handleDayChange}
        // onChange={(e) => handleDayOfWeekChange(e.target.value, e.target.checked)}
      />
      <label htmlFor={`day-${index}`} className="form-check-label">{day.slice(0, 3)}</label>
    </div>
  ))}
</div>

          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          <div className="form-group">
            <label>Time Zone:</label>
            <select  required  name="timezone" className="form-select custom-dorpdown-toglle mt-1" value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
              <option value="">Select Time Zone</option>
              <option value="Pacific">Pacific Time (UTC-08:00)</option>
      <option value="Mountain">Mountain Time (UTC-07:00)</option>
      <option value="Central">Central Time (UTC-06:00)</option>
      <option value="Eastern">Eastern Time (UTC-05:00)</option>
            </select>
            {/* <input type="text" className="form-control mt-1" value={timeZone} onChange={(e) => setTimeZone(e.target.value)} /> */}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Start Time:</label>
            <select
            required
            className="custom-dorpdown-toglle form-select mt-1"
            value={startTime}
            onChange={handleStartTimeChange}
            name="startime"
            disabled={!timeZone}
          >
            <option value="">Select Time</option>
            {times.map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>
            {/* <select required className="custom-dorpdown-toglle form-select mt-1" >
  <option value="">Select Time</option>
  {[...Array(12)].map((_, index) => (
    <React.Fragment key={index}>
      <option value={`${index + 1}:00 AM`}>{`${index + 1}:00 AM`}</option>
    </React.Fragment>
  ))}
  {[...Array(12)].map((_, index) => (
    <React.Fragment key={index}>
      <option value={`${index + 1}:00 PM`}>{`${index + 1}:00 PM`}</option>
    </React.Fragment>
  ))}
</select> */}

         

          </div>
          
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>End Time:</label>
           <select
            required
            name="endtime"
            className="form-select custom-dorpdown-toglle mt-1"
            value={endTime}
            onChange={handleEndTimeChange}
            disabled={!startTime} // Disable end time if start time is not selected
          >
            <option value="">Select Time</option>
            {filterEndTimes().map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>   
            {/* <select required className="form-select custom-dorpdown-toglle   mt-1" >
  <option value="">Select Time</option>
  {[...Array(12)].map((_, index) => (
    <React.Fragment key={index}>
      <option value={`${index + 1}:00 AM`}>{`${index + 1}:00 AM`}</option>
    </React.Fragment>
  ))}
  {[...Array(12)].map((_, index) => (
    <React.Fragment key={index}>
      <option value={`${index + 1}:00 PM`}>{`${index + 1}:00 PM`}</option>
    </React.Fragment>
  ))}
</select> */}

         

          </div>
          </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
        <div className="form-group">
            
            <label>Select Numbers:</label>
            {/* <select  required style={{backgroundColor: '#494949'}} class="form-control search-slt js-example-basic-multiple" name="Priority" multiple="multiple"  >
            {purchasednumbers.map((item) => (
              <option value={item.number}>{formatPhoneNumber(item.number)} {item.name}</option>

              ))}
               
                              
                            </select> */}
                           <div className="checkbox-container mt-2">
      {purchasednumbers.map((item, index) => (
        <div key={index} className="form-check form-check-inline checkbox-item mb-2">
          <input
            type="checkbox"
            id={`checkbox-${index}`}
            value={item.number}
            className="form-check-input"
             
            name="numbers" 
            checked={selectedNumbers.includes(item.number)}
                    // onChange={handleNumberChange}
            onChange={(e) => handleCheckboxChange(item.number, e.target.checked)}
            // onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
          />
          <label htmlFor={`checkbox-${index}`} className="form-check-label">
            {formatPhoneNumber(item.number)} {item.name}
          </label>
        </div>
      ))}
    </div>
        </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
          <label>Rate /Minute:</label>
            <select required value={rateminute}  onChange={(e) => setRateMinute(e.target.value)} name="rateminute" className="form-select custom-dorpdown-toglle mt-1" >
              <option value="">Select Minute</option>
              {generateOptions()}
            </select>
          </div>
        </div>
        <div className="row mb-2">
        <div className="col-md-6">
        
        </div>
        </div>
      </div>
      <button type="submit" className="btn btn-primary">Create Campaign</button>
    </form>
  
       

       
        </div>
      
        </div>
        </div>
        </div>
          
   
        </Sidebar>
        );
      }
    
}

export default CreateCampaign;
